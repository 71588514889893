import {ElectroDeviceTesting} from './electro-device-testing';
import {ElectroDeviceTestingItemLadderQuestionAnswer} from './electro-device-testing-item-ladder-question-answer';

export class ElectroDeviceTestingItem {
  id: number;
  vedin?: string;
  barcode?: number;
  nextTestingDateType = 3;
  comment?: string;
  registerNumber?: number;
  createdAt?: string;
  updatedAt?: string;
  location?: string;
  images: any[] = [];
  protectionClass?: number;
  deviceType?: string;
  deviceManufacturer?: string;
  nextDGUVV3?: string;
  deviceModel?: string;
  serialNumber?: string;
  installation?: string;
  testingDeviceId?: number;
  isCESymbolExist?: boolean;
  controlType?: number;
  property?: number; // 0, 1 or 2 currently
  testStatus?: number;
  electroDeviceTesting?: ElectroDeviceTesting;
  testingSending = false;
  parentItemId: number;
  isDuplicate = false;
  isTested = false;
  itemType?: number;
  ladderType?: number;
  materialType?: number;
  ladderLength?: number;
  totalRugs?: string;
  typeNumber?: string;
  purchasedAt?: string;
  ladderQuestions?: Array<ElectroDeviceTestingItemLadderQuestionAnswer> = [];
}
