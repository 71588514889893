import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {ToastrService} from 'ngx-toastr';
import {ElectroDeviceTestingService} from '../../../../services/electro-device-testing.service';
import {ElectroDeviceTypeService} from '../../../../services/electro-device-type.service';
import {ElectroDeviceManufacturerService} from '../../../../services/electro-device-manufacturer.service';
import {ElectroDeviceTesting} from '../../../../models/electro-device-testing';
import {ElectroDeviceTestingItem} from '../../../../models/electro-device-testing-item';
import {ElectroDeviceType} from '../../../../models/electro-device-type';
import {ElectroDeviceManufacturer} from '../../../../models/electro-device-manufacturer';
import {HelperService} from '../../../../services/helper.service';
import {ElectroDeviceTestingItemService} from '../../../../services/electro-device-testing-item.service';
import Swal from 'sweetalert2';
import {combineLatest} from 'rxjs';
import {DataResponse} from '../../../../models/data-response';
import {ElectroDeviceTestingItemLadderQuestionAnswer} from '../../../../models/electro-device-testing-item-ladder-question-answer';

@Component({
  selector: 'app-create-single-ladder',
  templateUrl: './create-single-ladder.component.html',
  styleUrls: ['./create-single-ladder.component.css']
})
export class CreateSingleLadderComponent implements OnInit {

  @Input() testing: ElectroDeviceTesting = null;
  @Output() done = new EventEmitter<boolean>(); // Emit when action is complete

  isChecked = false;


  isCreating = false;
  testingItem: ElectroDeviceTestingItem = new ElectroDeviceTestingItem();
  waiting = false;
  toggleItemView = false;
  isDuplicated = false;
  initialBarcode: number = null;
  imageQueue: FileItem[] = [];
  deviceTypeList: Array<ElectroDeviceType>;
  deviceManufacturerList: Array<ElectroDeviceManufacturer>;
  locationList: any[] = [];

  alerts = {
    thisBarcodeExistInTesting: false,
    thisBarcodeShouldNotTestedYet: false
  };

  protectionClassList = HelperService.protectionClassList;
  nextTestingDateList = HelperService.nextTestingDateList;
  yesNoList = HelperService.yesNoList;
  ladderTypeList = HelperService.ladderTypeList;
  materialList = HelperService.ladderMaterialTypeList;
  testingCriteriaGroups = HelperService.testingCriteriaList;


  testingResultList = [
    {id: 0, name: 'Nicht Bestanden', value: 0},
    {id: 1, name: 'Bestanden', value: 1},
    {id: 2, name: 'Fehlend', value: 2},
  ];
  imageOptionsInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Image, MineTypeEnum.Image_Png, MineTypeEnum.Image_Jpeg],
    disableMultipart: false
  };

  isAddingDeviceCategory = false;
  isAddingDeviceManufacturer = false;
  isAddingLocation = false;

  constructor(public fileUploader: HttpClientUploadService,
              private toastr: ToastrService,
              private electroDeviceTestingItemService: ElectroDeviceTestingItemService,
              private electroDeviceTestingService: ElectroDeviceTestingService,
              private electroDeviceTypeService: ElectroDeviceTypeService,
              private electroDeviceManufacturerService: ElectroDeviceManufacturerService) {
  }

  toggle() {
    this.isChecked = !this.isChecked;
  }

  ngOnInit() {

    this.fileUploader.queue = [];
    this.fileUploader.onCancel$.subscribe(
      (data: FileItem) => {

      });

    this.fileUploader.onDropError$.subscribe(
      (err) => {
        this.toastr.error('Image cannot be added to the Image-Queue!');
      });

    this.fileUploader.onAddToQueue$.subscribe((fileItem: FileItem) => {
      this.imageQueue.push(fileItem);
    });

    this.getLocationList();
  }

  search() {

    if (!this.testingItem.barcode) {
      this.toastr.error('Ungültiges Barcode!');
      return;
    }

    this.alerts.thisBarcodeExistInTesting = false;
    this.waiting = true;


    this.electroDeviceTestingItemService.barcodeSearch(this.testing, this.testingItem).subscribe(resp => {

      if (resp.data) {

        // We check, if it is an existing Barcode or New one for the current building
        if (resp.data.hasOwnProperty('barcode')) {
          this.testingItem = resp.data;
          this.alerts.thisBarcodeShouldNotTestedYet = resp.data.shouldNotBeTested;
        }
      }

      // we searched above the barcode in inventory and here we search the barcode if its exists in testing-items table
      this.checkBarcode(this.testingItem.barcode);

      // we always set default values
      this.setDefaultValues();
    });
  }

  private getLocationList() {
    console.dir(this.testing);
    this.electroDeviceTestingService.getLocationList(this.testing.id).subscribe(value => {

      this.locationList = [];

      // Ensure value is an object
      if (value.data && typeof value === 'object') {
        Object.entries(value.data).forEach(([key, name]) => {
          this.locationList.push({name: name as string, value: Number(key)});
        });
      }
    });
  }

  private getElectroDeviceManufacturerList() {
    this.electroDeviceManufacturerService.getNameList().subscribe(value => {
      this.deviceManufacturerList = [...value.data];
    });
  }

  private getElectroDeviceTypeList() {
    this.electroDeviceTypeService.getNameList().subscribe(value => {
      this.deviceTypeList = [...value.data];
    });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  checkBarcode(barcode: number) {
    this.electroDeviceTestingItemService.barcodeCheck(this.testing, barcode).subscribe(resp => {
      const isItemAlreadyTested = resp.data.exists;
      if (isItemAlreadyTested) {
        this.initialBarcode = this.testingItem.barcode;
        this.testingItem.barcode = null;
        this.alerts.thisBarcodeExistInTesting = true;
      } else {
        this.getElectroDeviceTypeList();
        this.getElectroDeviceManufacturerList();
        this.toggleItemView = true;
      }

      this.waiting = false;
    });
  }

  cloneDevice() {
    this.isDuplicated = true;
    this.testingItem.barcode = null;
    this.testingItem.comment = '';
    this.testingItem.registerNumber = null;
  }

  close(status = false): void {
    this.done.emit(status); // Notify parent of cancellation
    Swal.close();
  }

  removeImage(fileItem: FileItem) {
    this.imageQueue = this.imageQueue.filter((_fileItem: FileItem) => _fileItem !== fileItem);
  }

  toggleAddDeviceCategory(isActive = false) {
    this.testingItem.deviceType = null;
    this.isAddingDeviceCategory = isActive;
  }

  toggleAddDeviceManufacturer(isActive = false) {
    this.testingItem.deviceManufacturer = null;
    this.isAddingDeviceManufacturer = isActive;
  }

  async create() {
    const canProceed = await this.canCreate(this.testingItem);

    if (!canProceed) {
      return;
    }


    // Add leiter questions

    if (!this.testingItem.ladderQuestions) {
      this.testingItem.ladderQuestions = [];
    }

    this.testingCriteriaGroups.items.forEach(value => {

      const ladderQuestion = new ElectroDeviceTestingItemLadderQuestionAnswer();
      ladderQuestion.questionId = value.id;
      ladderQuestion.value = value.testStatus;
      ladderQuestion.comment = value.comment;
      this.testingItem.ladderQuestions.push(ladderQuestion);
    });

    this.isCreating = true;
    if (this.imageQueue && this.imageQueue.length > 0) {

      const uploadObservables = this.imageQueue.map((fileItem: FileItem) =>
        this.electroDeviceTestingItemService.uploadImage(fileItem)
      );

      combineLatest(uploadObservables).subscribe(
        responses => {
          responses.forEach((response: DataResponse<any>) => {
            this.onAddImage(response.data);
          });

          this.processTestingItem(); // Continue processing the item
        },
        error => {
          this.toastr.error('Die Bilder konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut!');
          this.isCreating = false;
        }
      );
    } else {
      // No images to upload, process the item directly
      this.processTestingItem();
    }
  }

  processTestingItem() {
    this.electroDeviceTestingItemService.create(this.testing, this.testingItem).subscribe((
        dataResponse: DataResponse<ElectroDeviceTestingItem>) => {
        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message);
          this.isCreating = false;
        }

        if (dataResponse.status === 'success') {
          this.close(true);
          this.isCreating = false;
          return;
        }
      },
      error => {
        this.toastr.error(error.toString());
        this.isCreating = false;
      });

    this.waiting = false;
  }


  onAddImage(data: any): void {
    const fileData = {name: data.fileName};
    this.testingItem.images.push(fileData);
  }

  isValidProtectionClass(protectionClass: number): boolean {
    return HelperService.protectionClassList.some(pc => pc.id === protectionClass);
  }

  checkBarcodeExists(barcode: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.electroDeviceTestingItemService.barcodeCheck(this.testing, barcode).subscribe({
        next: (resp) => resolve(resp.data.exists),
        error: (err) => reject(err),
      });
    });
  }

  async canCreate(item: ElectroDeviceTestingItem) {

    const commentRequired = item.testStatus !== 1;

    item.deviceType = 'Leiter';
    item.itemType = 1;

    // Check if this barcode already in testing exists!
    try {
      const exists = await this.checkBarcodeExists(item.barcode);
      if (exists) {
        this.toastr.error(`Barcode ${item.barcode} darf nicht benutzt werden, da es bereits in diesem Gebäude getestet worden ist.`);
        return false;
      }
    } catch (error) {
      this.toastr.error('An error occurred while checking the barcode');
      return false;
    }

    if (!item.location) {
      this.toastr.error('Fehler: Standort fehlt!');
      return false;
    }

    if (!item.deviceType) {
      this.toastr.error('Fehler: Gerätekategorie ist nicht ausgewählt!');
      return false;
    }

    if (!item.deviceManufacturer) {
      this.toastr.error('Fehler: Gerätehersteller ist nicht ausgewählt!');
      return false;
    }

    let isFailed = false;
    this.testingCriteriaGroups.items.forEach(value => {
      if (!value.testStatus) {
        this.toastr.error('Bitte antworten: ' + value.name);
        isFailed = true;
      }
    });

    if (isFailed) {
      return false;
    }

    if (item.nextTestingDateType === 0 && item.testStatus !== 2) {
      this.toastr.error('Fehler: Nächste Prüfung muss ausgewählt werden!');
      return;
    }

    if (item.nextTestingDateType == null) {
      this.toastr.error('Fehler: Nächste Prüfung ist nicht ausgewählt!');
      return false;
    }

    if (item.testStatus == null) {
      this.toastr.error('Fehler: Ergebnis der Prüfung ist nicht ausgewählt!');
      return false;
    }

    if (commentRequired && !item.comment) {
      this.toastr.error('Fehler: Kommentar muss eingetragen werden!');
      return false;
    }

    return true;
  }

  /**
   * If the provided testItem lacks any of the following attributes, those attributes will be assigned default values.
   * @private
   */
  private setDefaultValues() {

    this.isDuplicated = false;

    this.isAddingDeviceCategory = false;
    this.isAddingDeviceManufacturer = false;

    this.testingItem.testStatus = null;
    this.testingItem.images = [];

    if (!this.testingItem.nextTestingDateType) {
      this.testingItem.nextTestingDateType = 3; // 1 Jahr
    }
  }

  onNumericInput(event: Event, limit = 6): void {
    const inputElement = event.target as HTMLInputElement;

    // Keep only numeric characters and limit to 6 digits
    inputElement.value = inputElement.value.replace(/[^0-9]/g, '').slice(0, limit);
  }

  toggleAddLocation(isActive = false) {
    this.testingItem.location = null;
    this.isAddingLocation = isActive;
  }

  cycleTestStatus(item: any) {
    item.testStatus = (item.testStatus + 1) % 3; // 0 → 1 → 2 → 0
  }
}
