import { Pipe, PipeTransform } from '@angular/core';
import {HelperService} from '../services/helper.service';

@Pipe({
  name: 'ladderQuestionType'
})
export class LadderQuestionTypePipe implements PipeTransform {

  transform(id: number): string {
    const data = HelperService.ladderQuestionList.find(item => item.id === id);
    return data ? data.name : '';
  }

}
