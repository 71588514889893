import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ElectroDeviceTestingItem} from '../../../../models/electro-device-testing-item';
import {DataResponse} from '../../../../models/data-response';
import {ElectroDeviceTestingItemService} from '../../../../services/electro-device-testing-item.service';
import Swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {HelperService} from '../../../../services/helper.service';
import {Page} from '../../../../models/page';
import {ElectroDeviceTypeService} from '../../../../services/electro-device-type.service';
import {ElectroDeviceManufacturerService} from '../../../../services/electro-device-manufacturer.service';
import {ElectroDeviceTestingDeviceService} from '../../../../services/electro-device-testing-device.service';
import {ElectroDeviceType} from '../../../../models/electro-device-type';
import {ElectroDeviceManufacturer} from '../../../../models/electro-device-manufacturer';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {ElectroDeviceTestingDevice} from '../../../../models/electro-device-testing-device';
import {combineLatest} from 'rxjs';
import {Image} from '../../../../models/image';
import {environment} from '../../../../../environments';
import {ElectroDeviceTestingService} from '../../../../services/electro-device-testing.service';

@Component({
  selector: 'app-testing-item-edit',
  templateUrl: './testing-item-edit.component.html',
  styleUrls: ['./testing-item-edit.component.css']
})
export class TestingItemEditComponent implements OnInit {

  @Input() testingItem: ElectroDeviceTestingItem = null;
  @Input() testingId!: any;
  @Output() done = new EventEmitter<boolean>();

  // Handle the added file
  selectedImagePath: any = null;
  isDisplayImageViewToggled = false;

  waiting = false;
  imageQueue: FileItem[] = [];
  testingDeviceList: ElectroDeviceTestingDevice[] = null;
  deviceTypeList: Array<ElectroDeviceType> = null;
  deviceManufacturerList: Array<ElectroDeviceManufacturer> = null;
  locationList: any[] = [];

  protectionClassList = HelperService.protectionClassList;
  nextTestingDateList = HelperService.nextTestingDateList;
  yesNoList = HelperService.yesNoList;
  controlTypeList = HelperService.controlTypeList;
  propertyList = HelperService.propertyList;
  testingResultList = [
    {id: 0, name: 'Nicht Bestanden', value: 0},
    {id: 1, name: 'Bestanden', value: 1},
    {id: 2, name: 'Fehlend', value: 2},
  ];
  CEList = HelperService.yesNoList;

  imageOptionsInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Image, MineTypeEnum.Image_Png, MineTypeEnum.Image_Jpeg],
    disableMultipart: false
  };

  imagesLoading = false;
  isAddingDeviceCategory = false;
  isAddingDeviceManufacturer = false;
  isAddingLocation = false;

  constructor(public fileUploader: HttpClientUploadService,
              private toastr: ToastrService,
              private electroDeviceTestingItemService: ElectroDeviceTestingItemService,
              private electroDeviceTestingService: ElectroDeviceTestingService,
              private electroDeviceTypeService: ElectroDeviceTypeService,
              private electroDeviceManufacturerService: ElectroDeviceManufacturerService,
              private electroDeviceTestingDeviceService: ElectroDeviceTestingDeviceService) {
  }

  ngOnInit() {

    this.fileUploader.queue = [];
    this.fileUploader.onCancel$.subscribe(
      (data: FileItem) => {

      });

    this.fileUploader.onDropError$.subscribe(
      (err) => {
        this.toastr.error('Image cannot be added to the Image-Queue!');
      });

    this.fileUploader.onAddToQueue$.subscribe((fileItem: FileItem) => {
      this.imageQueue.push(fileItem);
    });

    this.loadTestingDeviceList();
    this.loadElectroDeviceManufacturerList();
    this.loadElectroDeviceTypeList();
    this.getLocationList();
    this.loadImages();
  }

  private getLocationList() {
    this.electroDeviceTestingService.getLocationList(this.testingId).subscribe(value => {

      this.locationList = [];

      // Ensure value is an object
      if (value.data && typeof value === 'object') {
        Object.entries(value.data).forEach(([key, name]) => {
          this.locationList.push({ name: name as string, value: Number(key) });
        });
      }
    });
  }

  private loadTestingDeviceList() {

    const page = new Page();

    page.pageNumber = 0;
    page.size = 100;
    page.sortOrder = 'ASC';

    this.electroDeviceTestingDeviceService.getList(page).subscribe(response => {

      const data: ElectroDeviceTestingDevice[] = response.data;

      if (response.status === 'success') {
        this.testingDeviceList = [...data];
      }

      if (response.status === 'error') {
        this.toastr.error(response.message);
      }
    });
  }

  private loadElectroDeviceManufacturerList() {
    this.electroDeviceManufacturerService.getNameList().subscribe(value => {
      this.deviceManufacturerList = [...value.data];
    });
  }

  private loadElectroDeviceTypeList() {
    this.electroDeviceTypeService.getNameList().subscribe(value => {
      this.deviceTypeList = [...value.data];
    });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  close(status = false): void {
    this.done.emit(status); // Notify parent of cancellation
    Swal.close();
  }

  removeImageFromQueue(fileItem: FileItem) {
    this.imageQueue = this.imageQueue.filter((_fileItem: FileItem) => _fileItem !== fileItem);
  }

  toggleAddDeviceCategory(isActive = false) {
    this.testingItem.deviceType = null;
    this.isAddingDeviceCategory = isActive;
  }

  toggleAddDeviceManufacturer(isActive = false) {
    this.testingItem.deviceManufacturer = null;
    this.isAddingDeviceManufacturer = isActive;
  }

  update() {

    if (!this.canUpdate(this.testingItem)) {
      return;
    }

    if (this.imageQueue && this.imageQueue.length > 0) {
      const uploadObservables = this.imageQueue.map((fileItem: FileItem) =>
        this.electroDeviceTestingItemService.uploadImage(fileItem)
      );

      combineLatest(uploadObservables).subscribe(
        responses => {
          responses.forEach((response: DataResponse<any>) => {
            this.onAddImage(response.data);
          });

          this.processTestingItem(); // Continue processing the item
        },
        error => {
          this.toastr.error('Die Bilder konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut!');
          console.error('Image upload error:', error);
        }
      );
    } else {
      // No images to upload, process the item directly
      this.processTestingItem();
    }
  }

  processTestingItem() {
    this.waiting = true;

    this.electroDeviceTestingItemService
      .update(this.testingItem)
      .subscribe((dataResponse: DataResponse<ElectroDeviceTestingItem>) => {

          if (dataResponse.status === 'error') {
            this.toastr.error(dataResponse.message);
          }

          if (dataResponse.status === 'success') {
            this.close();
            return;
          }

          this.waiting = false;
        },
        error => {
          this.toastr.error(error.toString());
          this.waiting = false;
        });
  }

  isRegisterNumberRequired(testingDeviceId: number) {

    if (!this.testingDeviceList) {
      return;
    }

    let isRequired = false;
    this.testingDeviceList.forEach(function (testingDevice) {
      if (testingDevice.id === testingDeviceId) {
        isRequired = testingDevice.isRegisterNumberRequired;
        return isRequired;
      }
    });

    return isRequired;
  }

  onAddImage(data: any): void {
    const fileData = {name: data.fileName, upload: true};
    this.testingItem.images.push(fileData);
  }

  isValidProtectionClass(protectionClass: number): boolean {
    return HelperService.protectionClassList.some(pc => pc.id === protectionClass);
  }

  private canUpdate(item: ElectroDeviceTestingItem) {

    const commentRequired = item.testStatus !== 1;
    let registerNumberRequired = this.isRegisterNumberRequired(this.testingItem.testingDeviceId);

    if (item.testStatus === 2) {
      registerNumberRequired = false;
    }

    if (!item.location) {
      this.toastr.error('Fehler: Standort fehlt!');
      return false;
    }

    if (!this.isValidProtectionClass(item.protectionClass)) {
      this.toastr.error('Fehler: Schutzklasse (SK) fehlt!');
      return false;
    }

    if (!item.deviceType) {
      this.toastr.error('Fehler: Gerätekategorie ist nicht ausgewählt!');
      return false;
    }

    if (!item.deviceManufacturer) {
      this.toastr.error('Fehler: Gerätehersteller ist nicht ausgewählt!');
      return false;
    }

    if (item.testingDeviceId === null) {
      this.toastr.error('Fehler: Prüfgerät ist nicht ausgewählt!');
      return false;
    }

    if (item.nextTestingDateType === 0 &&  item.testStatus !== 2) {
      this.toastr.error('Fehler: Nächste Prüfung muss ausgewählt werden!');
      return;
    }

    if (item.nextTestingDateType == null) {
      this.toastr.error('Fehler: Nächste Prüfung ist nicht ausgewählt!');
      return false;
    }

    if (registerNumberRequired && !item.registerNumber) {
      this.toastr.error('Fehler: Die Speichernummer muss eingetragen werden!');
      return false;
    }

    if (commentRequired && !item.comment) {
      this.toastr.error('Fehler: Kommentar muss eingetragen werden!');
      return false;
    }

    return true;
  }

  loadImages() {

    this.imagesLoading = true;

    this.electroDeviceTestingItemService.getImages(this.testingItem.id)
      .subscribe((dataResponse: DataResponse<Image>) => {
        if (dataResponse.status === 'success') {
          const data = dataResponse.data;
          this.testingItem.images = [...data];
        }

        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message, 'Error');
        }

        this.imagesLoading = false;
      });
  }

  removeImageFromTestingItem(image: Image) {
    this.electroDeviceTestingItemService.deleteImage(this.testingItem.id, image.id).subscribe(
      response => {
        if (response.status === 'success') {
          this.testingItem.images = this.testingItem.images.filter(_image => _image.id !== image.id);
        } else {
          this.toastr.error(response.message);
        }
      },
      error => {
        this.toastr.error(error);
      }
    );
  }

  getTestingItemImagePath(image: Image) {
    return `${environment.apis.backend}/media/uploads/electro-device-testings/${image.name}`
  }

  displayImage(image: Image) {
    this.selectedImagePath = this.getTestingItemImagePath(image);
    this.isDisplayImageViewToggled = true;
  }

  closeDisplayImage() {
    this.selectedImagePath = null;
    this.isDisplayImageViewToggled = false;
  }


  onNumericInput(event: Event, limit =  6): void {
    const inputElement = event.target as HTMLInputElement;

    // Keep only numeric characters and limit to 6 digits
    inputElement.value = inputElement.value.replace(/[^0-9]/g, '').slice(0, limit);
  }

  toggleAddLocation(isActive = false) {
    this.testingItem.location = null;
    this.isAddingLocation = isActive;
  }
}
