import {Injectable} from '@angular/core';
import {environment} from '../../environments';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor() {
  }

  static testNormTypeMap: { [key: number]: string } = {
    1: 'DIN EN 50699 (VDE 0702)',
    2: 'DIN EN 62353 (VDE 0751-1)',
    3: 'DIN VDE 105-100',
    4: 'DIN EN 50678 (VDE 0701)',
    5: 'ÖVE/ÖNORM E 8701-1',
    6: 'DIN EN 131 (Leitern & Tritte)',
    7: 'DIN EN 14183 (Leitern & Tritte)',
    8: 'DIN EN 1004 (Leitern & Tritte)'
  };

  static testTypeMap: { [key: number]: string } = {
    1: 'Elektrische Geräte und Betriebsmittel',
    2: 'Medizinische (elektrische) Geräte',
    3: 'Elektrische Anlagen',
    4: 'Leitern & Tritte',
  };

  static controlTypeList = [
    {id: 0, name: 'elektr. 230V', default: true},
    {id: 1, name: 'elektr. 400V', default: false}
  ];

  static yesNoList = [
    {id: 0, name: 'Ja', value: true},
    {id: 1, name: 'Nein', value: false},
  ];

  static nextTestingDateList = [
    {id: 0, name: 'Keine Auswahl', value: false},
    {id: 1, name: '3 Monate', value: false},
    {id: 2, name: '6 Monate', value: false},
    {id: 3, name: '1 Jahr', value: true},
    {id: 4, name: '2 Jahre', value: false},
    {id: 5, name: '3 Jahre', value: false},
    {id: 6, name: '4 Jahre', value: false},
    {id: 7, name: '5 Jahre', value: false}
  ];

  static protectionClassList = [
    {id: 0, name: 'I'},
    {id: 1, name: 'II'},
    {id: 2, name: 'III'},
  ];

  static testingTypeList = [
    {id: 1, name: 'Ja'},
    {id: 0, name: 'Nein'},
    {id: 2, name: 'Fehlend'},
  ];

  static propertyList = [
    {id: 0, name: 'Einrichtung', default: false},
    {id: 1, name: 'Bewohner', default: false},
    {id: 2, name: 'Unternehmen', default: true}
  ];

  static ladderTypeList = [
    {id: 0, name: 'Anlegeleiter', value: 0},
    {id: 1, name: 'Schiebeleiter', value: 1},
    {id: 2, name: 'Seilzugleiter', value: 2},
    {id: 3, name: 'Stehleiter', value: 3},
    {id: 4, name: 'Mehrzweckleiter', value: 4},
    {id: 5, name: 'Podestleiter', value: 5},
    {id: 6, name: 'Teleskopleiter', value: 6},
    {id: 7, name: 'Steckleiter', value: 7},
    {id: 8, name: 'Einhängeleiter', value: 8},
    {id: 9, name: 'Leitertritt', value: 9},
    {id: 10, name: 'Treppentritt', value: 10},
    {id: 11, name: 'Rollentritt', value: 11},
    {id: 12, name: 'Sonstige', value: 12},
  ];

  static ladderMaterialTypeList = [
    {id: 0, name: 'Aluminium', value: 0},
    {id: 1, name: 'Holz', value: 1},
    {id: 2, name: 'Kunststoff', value: 2},
    {id: 3, name: 'Stahl', value: 3},
    {id: 4, name: 'Edelstahl', value: 4}
  ];
  static ladderQuestionList: { id: number; name: string }[] = [
    {id: 0, name: 'Sind die Holme/Schenkel (aufrechtstehende Teile) in einem guten Zustand?'},
    {id: 1, name: 'Sind Befestigungen (normalerweise Nieten, Schrauben Bolzen) vorhanden und in einem guten Zustand?'},
    {id: 2, name: 'Sind Führungsbügel vorhanden und in einem guten Zustand?'},
    {id: 3, name: 'Sind Leiterfüße/Fußkappen/Rollen vorhanden und in einem guten Zustand?'},
    {id: 4, name: 'Ist vorhandenes Zubehör vollständig und sicher befestigt?'},
    {id: 5, name: 'Sind die Holme/Schenkel um die Fixierpunkte für andere Teile in einem guten Zustand?'},
    {id: 6, name: 'Sind alle Sprossen/Stufen vorhanden und in einem guten Zustand?'},
    {id: 7, name: 'Sind Sprossenhaken vorhanden und in einem guten Zustand?'},
    {id: 8, name: 'Ist die gesamte Leiter frei von Verunreinigungen wie Schmutz, Farbe, Öl oder Fett?'},
    {id: 9, name: 'Sind die Verriegelungsschnapper in einem guten Zustand?'},
    {id: 10, name: 'Ist die Sicherheitskennzeichnung gut lesbar und vollständig angebracht?'},
    {id: 11, name: 'Ist die Leiter frei von sonstigen Mängeln?'},
  ];

  static get testingCriteriaList() {
    return {
      items: this.ladderQuestionList.map(q => ({
        id: q.id,
        name: q.name,
        testStatus: 0,
        comment: ''
      }))
    };
  }

  static getAPIUrl(s?: string) {
    return s ? `${environment.apis.backend}/${s.replace(/^\/+/, '')}` : environment.apis.backend;
  }

  static formatBarcode(barcode: any) {
    return ('000000' + barcode).slice(-6);
  }


  static padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }

  static isDate(value: any) {
    return value ? !isNaN((new Date(value)).getTime()) : false;
  }
}
