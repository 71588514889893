import {Component, OnInit} from '@angular/core';
import {DataResponse} from '../../../../models/data-response';
import {ElectroDeviceTestingItem} from '../../../../models/electro-device-testing-item';
import {ElectroDeviceTestingItemService} from '../../../../services/electro-device-testing-item.service';
import {ElectroDeviceTesting} from '../../../../models/electro-device-testing';
import {Table} from '../../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {Page} from '../../../../models/page';
import {ToastrService} from 'ngx-toastr';
import {SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {HelperService} from '../../../../services/helper.service';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from '../../../../services/authentication.service';
import {environment} from '../../../../../environments';

@Component({
  selector: 'app-testing-item-list',
  templateUrl: './testing-item-list.component.html',
  styleUrls: ['./testing-item-list.component.css']
})
export class TestingItemListComponent implements OnInit {

  testType: number | null = null;
  loaded = false;

  testingId = null;
  selectedTestingItem: ElectroDeviceTestingItem = null;

  exporting = false;
  exportInfoReady = false;
  exportFile = null;
  editTestingItemLadderModalOptions = {
    title: 'Geprüfte Leiter bearbeiten',
    cancelButtonColor: '#111',
    showCancelButton: false,
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    allowOutsideClick: false,
    width: '60%',
    onOpen: function () {
      // Logic to execute when the modal opens
      this.initializeModalContent();
    },
  };
  editTestingItemModalOptions = {
    title: 'Geprüftes Gerät bearbeiten',
    cancelButtonColor: '#111',
    showCancelButton: false,
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    allowOutsideClick: false,
    width: '60%',
    onOpen: function () {
      // Logic to execute when the modal opens
      this.initializeModalContent();
    },
  };

  exportCSVModalOptions = {
    title: 'Geprüfte-Geräte Exportieren',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    width: '800px',
    allowOutsideClick: () => !this.exporting
  };

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [],
    rowList: new Array<ElectroDeviceTesting>()
  };

  constructor(private electroDeviceTestingItemService: ElectroDeviceTestingItemService,
              public readonly swalTargets: SwalPartialTargets,
              private route: ActivatedRoute,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService) {
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.testType = +params['testType'];
      this.loaded = true;
    });

    this.testingId = this.route.snapshot.paramMap.get('id');
    this.getElectroDeviceTestingItems(0);
  }

  searchRemote($event: KeyboardEvent) {
    if ($event.code === 'Enter') {
      this.getElectroDeviceTestingItems(0);
    }
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: number = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  getElectroDeviceTestingItems(pageNumber: any) {


    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;

    this.electroDeviceTestingItemService.getList(this.testingId, this.table.page)
      .subscribe((dataResponse: DataResponse<ElectroDeviceTestingItem>) => {
        if (dataResponse.status === 'success') {
          if (dataResponse.page !== null) {
            this.table.page = dataResponse.page;
          }

          const data = dataResponse.data;

          this.table.rowList = [...data];
        }

        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message, 'Error');
        }

        this.table.loading = false;
      });
  }


  delete() {
    const that = this;
    this.electroDeviceTestingItemService.delete(this.selectedTestingItem.id).subscribe(resp => {
      if (resp.status === 'success') {
        this.getElectroDeviceTestingItems(0);
      } else {
        that.toastr.error(resp.message);
      }
    });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  onActivate(event: any) {

  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  onDelete(rowList: any[]) {
  }

  onSort(event: any) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getElectroDeviceTestingItems(0);
  }

  formatBarcode(barcode: any) {
    return HelperService.formatBarcode(barcode);
  }

  handleEditDoneResponse() {
    // get the current page, so it wont jump on edit to the beginning
    // this.getElectroDeviceTestingItems(0); // Reload data on success
  }

  setSelectedTestingItem(row: any): void {
    // We just beautify the barcode already for the edit modal.
    row.barcode = row.barcode;
    this.selectedTestingItem = row;
  }

  getPropertyName(property: number) {
    const _property = HelperService.propertyList.find(item => item.id === property);
    return _property ? _property.name : '';
  }

  export(type: string) {

    this.exportFile = null;
    this.exporting = true;

    const self = this;

    if (type === 'csv') {
      setTimeout(function () {
        self.exportInfoReady = true;
      }, 1000, self);

      this.electroDeviceTestingItemService.export(this.testingId).subscribe(resp => {
        this.exporting = false;
        this.exportFile = resp.data.fileName;
      });
    }
  }

  getExportFile() {
    return this.exportFile ? `${environment.apis.backend}/media/temp/reports/${this.exportFile}` : 'no-file-found';
  }

  cancelExport() {
    this.swalTargets.cancelButton().click();
    this.exporting = false;
    this.exportFile = null;
  }

  isDate(value: any) {
    return HelperService.isDate(value);
  }
}
